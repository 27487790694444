import { createApp } from "vue"
import App from "./App.vue"
// import "./registerServiceWorker"
import router from "./router"
import Vant from "vant"
import "vant/lib/index.less"
import "@/assets/style/index.less"
import MyCard from "@/components/card/Main.vue"
import MyCardBody from "@/components/card-body/Main.vue"
import filter from "@/assets/script/filter/main.filter"
import { Component } from "@vue/runtime-core"
import { i18n } from "../locales"
import DatePicker from "vant"

const app = createApp((App as unknown) as Component)
app
  .use(router)
  .use(Vant)
  .use(i18n)
  .use(DatePicker)

  .component("my-card", (MyCard as unknown) as Component)
  .component("my-card-body", (MyCardBody as unknown) as Component)
  .mount("#app")
app.config.globalProperties.$filter = filter
