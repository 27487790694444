import httpUtil from "@/assets/script/utils/http.util"
import { Result } from "@/assets/script/model/main.model"

export class HealthService {
  static healthRealtimeReport(
    healthReportId: string
    // ,token: string
  ): Promise<Result<string>> {
    // return httpUtil.get("/health/healthRealtimeReport", {}, { token })
    return httpUtil.get("/health/healthRealtimeReport", { healthReportId })
  }

  static healthReportHistory(
    deviceCiphertextId: string,
    userId: string,
    currentDateString: string,
    token: string
  ): Promise<Result<string>> {
    return httpUtil.get(
      "/health/healthReportHistory",
      { deviceCiphertextId, userId, currentDateString, token },
      { token }
    )
  }
}
