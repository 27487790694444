import { toDisplayString as _toDisplayString, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "page" }
const _hoisted_2 = { class: "items-center" }
const _hoisted_3 = { class: "pa-4" }
const _hoisted_4 = { style: {"color":"white","font-size":"18px","font-style":"blod"} }
const _hoisted_5 = {
  key: 0,
  style: {"padding":"5px"}
}
const _hoisted_6 = { class: "time-title topFontReport" }
const _hoisted_7 = {
  class: "containerReportWrapper",
  style: {"display":"flex","flex-wrap":"wrap","padding-top":"10px","padding-bottom":"16px"}
}
const _hoisted_8 = { class: "bigHealthContainerReport" }
const _hoisted_9 = { class: "bigHealthTop topFontReport" }
const _hoisted_10 = { class: "bigHealthBottom" }
const _hoisted_11 = { class: "iconFontReport" }
const _hoisted_12 = { class: "bigHealthContainerReport" }
const _hoisted_13 = { class: "bigHealthTop topFontReport" }
const _hoisted_14 = { class: "bigHealthBottom" }
const _hoisted_15 = { class: "iconFontReport" }
const _hoisted_16 = { class: "bigHealthContainerReport" }
const _hoisted_17 = { class: "bigHealthTop topFontReport" }
const _hoisted_18 = { class: "bigHealthBottom" }
const _hoisted_19 = { class: "iconFontReport" }
const _hoisted_20 = { class: "bigHealthContainerReport" }
const _hoisted_21 = { class: "bigHealthTop topFontReport" }
const _hoisted_22 = { class: "bigHealthBottom" }
const _hoisted_23 = { class: "iconFontReport" }
const _hoisted_24 = { class: "bigHealthContainerReport" }
const _hoisted_25 = { class: "bigHealthTop topFontReport" }
const _hoisted_26 = { class: "bigHealthBottom" }
const _hoisted_27 = { class: "iconFontReport" }
const _hoisted_28 = { class: "bigHealthContainerReport" }
const _hoisted_29 = { class: "bigHealthTop topFontReport" }
const _hoisted_30 = { class: "bigHealthBottom" }
const _hoisted_31 = { class: "bigHealthContainerReport" }
const _hoisted_32 = { class: "bigHealthTop topFontReport" }
const _hoisted_33 = { class: "bigHealthBottom" }
const _hoisted_34 = { class: "bigHealthContainerReport" }
const _hoisted_35 = { class: "bigHealthTop topFontReport" }
const _hoisted_36 = { class: "bigHealthBottom" }
const _hoisted_37 = { class: "bigHealthContainerReport" }
const _hoisted_38 = { class: "bigHealthTop topFontReport" }
const _hoisted_39 = { class: "bigHealthBottom" }
const _hoisted_40 = {
  class: "pa-4",
  style: {"color":"white","font-size":"18px","font-style":"blod","padding-bottom":"10px"}
}
const _hoisted_41 = {
  class: "pa-4",
  style: {"color":"white","font-size":"18px","font-style":"blod","padding-bottom":"10px"}
}
const _hoisted_42 = { class: "d-flex items-center " }
const _hoisted_43 = { style: {"color":"white","font-size":"18px","font-style":"blod","padding-bottom":"10px"} }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_my_card_body = _resolveComponent("my-card-body")
  const _component_my_card = _resolveComponent("my-card")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_my_card, { style: {"margin-top":"8px","background-color":"#132b51"} }, {
      default: _withCtx(() => [
        _createVNode(_component_my_card_body, null, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_my_card, null, {
                default: _withCtx(() => [
                  _createVNode("div", _hoisted_3, [
                    _createVNode(_component_my_card_body, null, {
                      default: _withCtx(() => [
                        _createVNode("p", _hoisted_4, _toDisplayString($setup.reportTitle), 1),
                        ($setup.time !== '')
                          ? (_openBlock(), _createBlock("div", _hoisted_5, [
                              _createVNode("div", _hoisted_6, _toDisplayString($setup.time), 1)
                            ]))
                          : _createCommentVNode("", true),
                        _createVNode("div", null, [
                          _createVNode("div", _hoisted_7, [
                            _createVNode("div", _hoisted_8, [
                              _createVNode("div", _hoisted_9, _toDisplayString(_ctx.$t("healthReport.heartRate")), 1),
                              _createVNode("div", _hoisted_10, [
                                _createVNode("div", _hoisted_11, _toDisplayString($setup.healthReportData && $setup.healthReportData.htrate
                              ? $setup.healthReportData.htrate
                              : "--"), 1)
                              ])
                            ]),
                            _createVNode("div", _hoisted_12, [
                              _createVNode("div", _hoisted_13, _toDisplayString(_ctx.$t("healthReport.bloodOxygen")), 1),
                              _createVNode("div", _hoisted_14, [
                                _createVNode("div", _hoisted_15, _toDisplayString($setup.healthReportData && $setup.healthReportData.spo
                              ? $setup.healthReportData.spo
                              : "--"), 1)
                              ])
                            ]),
                            _createVNode("div", _hoisted_16, [
                              _createVNode("div", _hoisted_17, _toDisplayString(_ctx.$t("healthReport.microcirculation")), 1),
                              _createVNode("div", _hoisted_18, [
                                _createVNode("div", _hoisted_19, _toDisplayString($setup.healthReportData && $setup.healthReportData.bk
                              ? $setup.healthReportData.bk
                              : "--"), 1)
                              ])
                            ]),
                            _createVNode("div", _hoisted_20, [
                              _createVNode("div", _hoisted_21, _toDisplayString(_ctx.$t("healthReport.systolicPressure")), 1),
                              _createVNode("div", _hoisted_22, [
                                _createVNode("div", _hoisted_23, _toDisplayString($setup.healthReportData && $setup.healthReportData.sdbp.sbp
                              ? $setup.healthReportData.sdbp.sbp
                              : "--"), 1)
                              ])
                            ]),
                            _createVNode("div", _hoisted_24, [
                              _createVNode("div", _hoisted_25, _toDisplayString(_ctx.$t("healthReport.diastolicPressure")), 1),
                              _createVNode("div", _hoisted_26, [
                                _createVNode("div", _hoisted_27, _toDisplayString($setup.healthReportData && $setup.healthReportData.sdbp.dbp
                              ? $setup.healthReportData.sdbp.dbp
                              : "--"), 1)
                              ])
                            ]),
                            _createVNode("div", _hoisted_28, [
                              _createVNode("div", _hoisted_29, _toDisplayString(_ctx.$t("healthReport.breathe")), 1),
                              _createVNode("div", _hoisted_30, [
                                _createVNode("div", {
                                  class: "iconFontReport",
                                  style: {
                            color:
                              $setup.healthReportData && $setup.healthReportData.rr > 0
                                ? '#b1a62b'
                                : ''
                          }
                                }, _toDisplayString($setup.healthReportData && $setup.healthReportData.rr
                              ? $setup.healthReportData.rr
                              : "--"), 5)
                              ])
                            ]),
                            _createVNode("div", _hoisted_31, [
                              _createVNode("div", _hoisted_32, _toDisplayString(_ctx.$t("healthReport.SDNN")), 1),
                              _createVNode("div", _hoisted_33, [
                                _createVNode("div", {
                                  class: "iconFontReport",
                                  style: {
                            color:
                              $setup.healthReportData && $setup.healthReportData.bk
                                ? '#b1a62b'
                                : ''
                          }
                                }, _toDisplayString($setup.healthReportData && $setup.healthReportData.hrv
                              ? $setup.healthReportData.hrv
                              : "--"), 5)
                              ])
                            ]),
                            _createVNode("div", _hoisted_34, [
                              _createVNode("div", _hoisted_35, _toDisplayString(_ctx.$t("healthReport.Arrhythmias")), 1),
                              _createVNode("div", _hoisted_36, [
                                _createVNode("div", {
                                  class: "iconFontReport",
                                  style: {
                            color:
                              $setup.healthReportData &&
                              $setup.healthReportData.arrhythmia > 0
                                ? '#993030'
                                : ''
                          }
                                }, _toDisplayString($setup.healthReportData && $setup.healthReportData.arrhythmia
                              ? $setup.healthReportData.arrhythmia
                              : "--"), 5)
                              ])
                            ]),
                            _createVNode("div", _hoisted_37, [
                              _createVNode("div", _hoisted_38, _toDisplayString(_ctx.$t("healthReport.fatigue")), 1),
                              _createVNode("div", _hoisted_39, [
                                _createVNode("div", {
                                  class: "iconFontReport",
                                  style: {
                            color: $setup.fatigueColor
                          }
                                }, _toDisplayString($setup.fatigue ? $setup.fatigue : "--"), 5)
                              ])
                            ])
                          ])
                        ])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_my_card, { style: {"margin-top":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_my_card_body, null, {
          default: _withCtx(() => [
            _createVNode("div", null, [
              _createVNode(_component_my_card, null, {
                default: _withCtx(() => [
                  _createVNode("div", null, [
                    _createVNode(_component_my_card_body, null, {
                      default: _withCtx(() => [
                        _createVNode("p", _hoisted_40, _toDisplayString(_ctx.$t("healthReport.characteristicWaveform")), 1),
                        _createVNode("div", {
                          id: "lineChart",
                          ref: $setup.setRef,
                          style: {"width":"100%","height":"200px","left":"10px"}
                        }, null, 512)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_my_card, { style: {"margin-top":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_my_card_body, null, {
          default: _withCtx(() => [
            _createVNode("div", null, [
              _createVNode(_component_my_card, null, {
                default: _withCtx(() => [
                  _createVNode("div", null, [
                    _createVNode(_component_my_card_body, null, {
                      default: _withCtx(() => [
                        _createVNode("p", _hoisted_41, _toDisplayString(_ctx.$t("healthReport.RR")), 1),
                        _createVNode("div", {
                          id: "lineChartRR",
                          ref: $setup.setRefRR,
                          style: {"width":"100%","height":"200px","left":"10px"}
                        }, null, 512)
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }),
    _createVNode(_component_my_card, { style: {"margin-top":"10px"} }, {
      default: _withCtx(() => [
        _createVNode(_component_my_card_body, null, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_42, [
              _createVNode(_component_my_card, null, {
                default: _withCtx(() => [
                  _createVNode("div", null, [
                    _createVNode(_component_my_card_body, { class: "pa-4" }, {
                      default: _withCtx(() => [
                        _createVNode("p", _hoisted_43, _toDisplayString(_ctx.$t("healthReport.report")), 1),
                        _createVNode("p", {
                          style: {"color":"white","font-size":"18px","font-style":"blod","font-weight":"500","padding-bottom":"10px"},
                          innerHTML: 
                    $setup.healthReportData && $setup.healthReportData.report
                      ? $setup.healthReportData.report
                      : '--'
                  
                        }, null, 8, ["innerHTML"])
                      ]),
                      _: 1
                    })
                  ])
                ]),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    })
  ]))
}