export const NOT_FOUND = 404

export const PERMISSION = 403

export const PROGRAM = 500

export const SIGN_IN = 401

export const INIT = 503

export const SUCCESS = 200

export enum Platform {
  ANDROID = "Android",
  IOS = "IOS",
  MINI_PROGRAM = "miniprogram"
}
