export default [
  {
    path: "/instructionalVideo",
    component: () => import("../Main.vue")
  },
  {
    path: "/instructionalVideo/:key",
    props: true,
    component: () => import("../Detail.vue")
  }
]
