// import jsCookie from "js-cookie"
/**
 * http请求的工具类
 *
 * @author fjj
 */
import axios from "axios"
import qs from "qs"
import { Result } from "../model/main.model"
import { API_HOST } from "../constant/config.constant"
import { PROGRAM } from "@/assets/script/constant/main.constant"

axios.defaults.baseURL = API_HOST
export default {
  async get(url: string, params?: Record<string, any>, headers?: any) {
    let result: Result<any>
    try {
      const response = await axios.get(url, {
        params,
        headers: Object.assign({ version: "3.0" }, headers),
        paramsSerializer: (params: Record<string, any>) => {
          return qs.stringify(params, {
            arrayFormat: "repeat"
          })
        }
      })
      result = response.data
    } catch (e) {
      result = await new Result(PROGRAM, e, "服务器错误")
    }
    return result
  },

  /**
   * post 请求
   */
  async post(url: string, body?: Record<string, any>, type = "json") {
    let result = null
    try {
      const response = await axios.post(
        url,
        type === "json"
          ? body
          : qs.stringify(body, {
              arrayFormat: "repeat"
            })
      )
      result = response.data
    } catch (e) {
      result = await new Result(PROGRAM, e, "服务器错误")
    }
    return result
  }
}
