export default [
  {
    path: "/gravity",
    props: true,
    component: () => import("../Main.vue")
  },
  {
    path: "/gravity/realtime",
    props: true,
    component: () => import("../Realtime.vue")
  },
  {
    path: "/gravity/bmi/:weight/:sex",
    props: true,
    component: () => import("../BMI.vue")
  }
]
