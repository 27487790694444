// // 宿舍开发环境
// export const API_HOST = "http://192.168.31.138:8653/"

// export const WS_HOST = "ws://192.168.31.138:9510/ws"

// export const IFRAME_TWO_HOST = "http://192.168.27.10:4103"

// export const IFRAME_HOST = "https://api.mpebedding.com/heatmap/hea192.168.27.10tmap.html"

// export const HYBRID_HOST = "https://hybrid.mpebedding.com"

// export const OFFICIAL_APP_ID = "wxebbe0dfd9e1e35f0"

// export const COUNT_VIEW_MODEL_KEY = "COUNT_VIEW_MODEL_KEY"

// 公司开发环境
// export const API_HOST = "http://192.168.27.10:8653/"

// export const WS_HOST = "ws://192.168.27.10:9510/ws"

// export const IFRAME_TWO_HOST = "http://192.168.27.10:4102"

// export const IFRAME_HOST = "https://api.mpebedding.com/heatmap/heatmap.html"

// export const HYBRID_HOST = "https://hybrid.mpebedding.com"

// export const OFFICIAL_APP_ID = "wxebbe0dfd9e1e35f0"

// export const COUNT_VIEW_MODEL_KEY = "COUNT_VIEW_MODEL_KEY"

// 正式环境
export const IFRAME_HOST = "https://api.mpebedding.com/heatmap/heatmap.html"
export const IFRAME_TWO_HOST = "https://hybrid.mpebedding.com"

// export const API_HOST ="http://121.37.210.87:8654"

export const API_HOST =
  process.env.NODE_ENV === "development"
    ? "/api"
    : "https://api.mpebedding.com/"
export const WS_HOST =
  process.env.NODE_ENV === "development"
    ? "wss://api.mpebedding.com/ws"
    : "wss://api.mpebedding.com/ws"

export const HYBRID_HOST = "https://hybrid.mpebedding.com"

export const OFFICIAL_APP_ID = "wxebbe0dfd9e1e35f0"

export const COUNT_VIEW_MODEL_KEY = "COUNT_VIEW_MODEL_KEY"
