
import { ref, onDeactivated, onMounted, computed } from "vue"
import { Button, Icon, DatetimePicker } from "vant"
import { HealthService } from "@/assets/script/service/health.service"
import { SUCCESS } from "@/assets/script/constant/main.constant"

import { HealthReportHistoryData } from "../realtime/constants"
import { useRouter } from "vue-router"
import { useI18n } from "vue-i18n"

interface Props {
  userId: string
  deviceCiphertextId: string
}
export default {
  props: ["deviceCiphertextId", "userId"],
  components: {
    [Button.name]: Button,
    [DatetimePicker.name]: DatetimePicker,
    [Icon.name]: Icon
  },
  setup(props: Props) {
    const router = useRouter()
    const { t } = useI18n()

    // console.log("userId=" + props.userId)

    const showPicker = ref(false) // 控制 DatetimePicker 是否显示的变量
    const currentDate = new Date() // 初始化为 null

    const currentButtonText = ref("")

    const redirectToReport = (id: any, time: string) => {
      // router.push({
      //   name: "healthReport",
      //   query: { healthReportId: id, healthReportFalg: "2", time: time }
      // })
      router.push({
        name: "healthReport",
        params: {
          healthReportId: id,
          healthReportFalg: "2"
        },
        query: {
          time: time
        }
      })
    }

    const getCurrentDate = () => {
      const date = new Date()
      const year = date.getFullYear()
      let month: number | string = date.getMonth() + 1
      let day: number | string = date.getDate()

      // 如果月份或日期小于10，在前面补0
      month = month < 10 ? "0" + month : month
      day = day < 10 ? "0" + day : day
      currentButtonText.value = `${year}-${month}-${day}`
      // localStorage.setItem("dateString", currentButtonText);
    }

    // onMounted(() => {
    // })

    const healthReportHistoryListData = ref<
      HealthReportHistoryData[] | undefined
    >(undefined)

    const healthRealtimeReport = async (dateString: string) => {
      const result = await HealthService.healthReportHistory(
        props.deviceCiphertextId,
        props.userId,
        dateString,
        ""
      )
      if (result.data && result.status === SUCCESS) {
        const dataArray = result.data
        // console.log(dataArray)
        if (Array.isArray(dataArray)) {
          healthReportHistoryListData.value = dataArray.map((item) => {
            const createDate = new Date(item.createDate)
            return {
              id: item.id,
              createDate: new Date(createDate.getTime() + 8 * 60 * 60 * 1000)
                .toISOString()
                .replace("T", " ")
            }
          })
        }
      } else {
        throw new Error(result.message)
      }
    }

    // 当用户确认选择日期时调用
    const handleConfirm = (value: any) => {
      // console.log("确认选择的日期1:", value)
      // Create a new Date object from the selected value
      const selectedDate = new Date(value)
      // Extract the year, month, and day components from the selected date
      const year = selectedDate.getFullYear()
      const month = ("0" + (selectedDate.getMonth() + 1)).slice(-2) // Month is zero-indexed, so add 1
      const day = ("0" + selectedDate.getDate()).slice(-2)
      // Format the components into the desired format "YYYY-MM-DD"
      const formattedDate = `${year}-${month}-${day}`
      // console.log("确认选择的日期2:", formattedDate)
      healthRealtimeReport(formattedDate)
      currentButtonText.value = formattedDate
      localStorage.setItem("dateString", currentButtonText.value)
      showPicker.value = false // 隐藏 DatetimePicker
    }

    const next = async () => {
      const dateString = localStorage.getItem("dateString")
        ? localStorage.getItem("dateString")
        : ""
      if (dateString) {
        currentButtonText.value = dateString
      } else {
        getCurrentDate()
      }
      // console.log("currentButtonText=" + currentButtonText.value)
      healthRealtimeReport(currentButtonText.value)
    }
    const ready = async () => {
      await next()
    }
    ready()

    return {
      showPicker,
      currentDate,
      handleConfirm,
      currentButtonText,
      healthReportHistoryListData,
      redirectToReport
    }
  }
}
