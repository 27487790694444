
import { Ref, watch, computed, nextTick } from "vue"
import echarts from "echarts"
import { dateToTimeList } from "@/assets/script/utils/date.util"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { useI18n } from "vue-i18n"
import { WS_HOST } from "@/assets/script/constant/config.constant"
import { SignInVo, UserService } from "@/assets/script/service/user.service"
import { sleep } from "@/assets/script/utils/heighten.util"

import {
  HealthRealtimeReportData,
  HealthReportFlag
} from "../realtime/constants"
import { ref, onDeactivated } from "vue"
import { useRouter } from "vue-router"

import { HealthService } from "@/assets/script/service/health.service"
import { SUCCESS } from "@/assets/script/constant/main.constant"
// import { truncate } from "./truncate";

import * as CryptoJS from "crypto-js"
import * as $ from "jquery"

interface TranslationResponse {
  tSpeakUrl: string
  requestId: string
  query: string
  translation: string[]
  mTerminalDict: {
    url: string
  }
  errorCode: string
  dict: {
    url: string
  }
  webdict: {
    url: string
  }
  l: string
  isWord: boolean
  speakUrl: string
}

interface Props {
  healthReportId: string
  healthReportFalg: string
}
export default {
  props: ["healthReportId", "healthReportFalg"],
  setup(props: Props) {
    const router = useRouter()
    const { t } = useI18n()

    function truncate(q: string): string {
      const len = q.length
      if (len <= 20) return q
      return q.substring(0, 10) + len + q.substring(len - 10, len)
    }

    let reportTitle = ""
    const healthReportFalg =
      router.currentRoute.value.query.healthReportFalg || ""
    const time = router.currentRoute.value.query.time || ""
    if (props.healthReportFalg == "1") {
      reportTitle = t("healthReport.realTimeRreport")
    } else if (props.healthReportFalg == "2") {
      reportTitle = t("healthReport.HistoricalRreport")
    }

    // const healthReportId = router.currentRoute.value.query.healthReportId || ""

    const healthReportData = ref<HealthRealtimeReportData | undefined>(
      undefined
    )

    const fatigue = ref("")
    const fatigueColor = ref("")

    const featureXAxisData: string[] = []

    // console.log("report healthReportId=" + healthReportId.toString())

    const healthRealtimeReport = async () => {
      const result = await HealthService.healthRealtimeReport(
        props.healthReportId
        // ,userInfo.value!.token
      )
      if (result.data && result.status === SUCCESS) {
        // console.log(result.data)
        healthReportData.value = JSON.parse(result.data).result[0]
        if (healthReportData.value && healthReportData.value.fatigue) {
          const fatigueIndex = healthReportData.value.fatigue
          switch (fatigueIndex) {
            case 1:
              fatigue.value = t("healthReport.fatigue1")
              fatigueColor.value = "#2fa988"
              break
            case 2:
              fatigue.value = t("healthReport.fatigue2")
              fatigueColor.value = "#b1a62b"
              break
            case 3:
              fatigue.value = t("healthReport.fatigue3")
              fatigueColor.value = "#993030"
              break
            default:
              fatigue.value = t("healthReport.fatigue4")
              break
          }
        }

        const length = healthReportData.value?.PulseWave.length || 0
        for (let i = 0; i <= length; i++) {
          featureXAxisData.push(i.toString())
        }

        if (
          healthReportData.value &&
          healthReportData.value.report &&
          t("locale") == "en"
        ) {
          //  console.log(healthReportData.value.report)
          const appKey = "2080a94bcc3b3276"
          const key = "sfyT3A5fAfCBwMvSU487z95Xb6j7xWwT"
          const vocabId = "1"

          const salt = new Date().getTime()
          const curtime = Math.round(new Date().getTime() / 1000)
          const query = healthReportData.value.report
          const from = "zh"
          const to = "en"

          const str1: string = appKey + truncate(query) + salt + curtime + key

          const sign: string = CryptoJS.SHA256(str1).toString(CryptoJS.enc.Hex)

          let res = ""

          $.ajax({
            url: "https://openapi.youdao.com/api",
            type: "post",
            dataType: "jsonp",
            data: {
              q: query,
              appKey: appKey,
              salt: salt,
              from: from,
              to: to,
              sign: sign,
              signType: "v3",
              curtime: curtime,
              vocabId: vocabId
            },
            success: function(data: any) {
              // console.log(data)
              const truncateText: TranslationResponse = JSON.parse(
                JSON.stringify(data)
              )
              // console.log(truncateText.translation)
              res = truncateText.translation[0]
              if (healthReportData.value) {
                // healthReportData.value.report = res.replace(/\./g, ".<br>").replaceAll(",",".")
                healthReportData.value.report = res
                  .replace(/\./g, ".<br>")
                  .replace(/(\d),/g, "$1.")

                // console.log(healthReportData.value.report);
              }
            }
          })
        }
        if (
          healthReportData.value &&
          healthReportData.value.report &&
          t("locale") != "en"
        ) {
          healthReportData.value.report = healthReportData.value.report.replace(
            /。/g,
            "。<br>"
          )
        }

        // console.log("featureXAxisData="+featureXAxisData)
      } else {
        // alert(result.message)
        throw new Error(result.message)
      }
    }

    const featureOption = computed(() => {
      return {
        animation: true,
        grid: {
          left: "0%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true
        },
        tooltip: {
          show: false,
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: featureXAxisData, // x轴的数据
          show: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "white"
            }
          }
        },
        yAxis: {
          x: "center",
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "gray"
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "white"
            }
          }
        },
        series: [
          {
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 1
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)"
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: healthReportData.value?.PulseWave
          }
        ]
      }
    })

    const rrOption = computed(() => {
      return {
        animation: true,
        xAxis: {
          max: 1800,
          axisLabel: {
            textStyle: {
              color: "white"
            }
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: "white"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "white"
            }
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          max: 1800,
          axisLabel: {
            textStyle: {
              color: "white"
            }
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: "white"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "white"
            }
          },
          splitLine: {
            show: false
          }
        },
        legend: {
          show: false,
          data: ["RR间期"]
        },
        visualMap: {
          show: false
        },
        tooltip: {
          show: false,
          trigger: "item"
        },
        grid: {
          left: "0%",
          right: "10%",
          top: "5%",
          bottom: "5%",
          containLabel: true
        },
        series: [
          {
            type: "scatter",
            name: "RR间期",
            symbolSize: 3,
            data: [],
            markLine: {
              animation: false,
              tooltip: {
                show: false
              },
              lineStyle: {
                type: "solid",
                color: "gray"
              },
              data: [
                [
                  { coord: [0, 0], symbol: "none" },
                  { coord: [1500, 1500], symbol: "none" }
                ]
              ]
            }
          }
        ],
        animationDelay: function(idx: number) {
          return 50 * idx
        },
        animationEasing: "elasticOut"
      }
    })

    let echartsDomRef: HTMLDivElement
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (echartsDomRef = el)

    let refRR: HTMLDivElement
    let echartsDomRR: echarts.ECharts
    const setRefRR = (el: HTMLDivElement) => (refRR = el)

    nextTick(() => {
      echartsDom = echarts.init(echartsDomRef)
      echartsDom.setOption(featureOption.value as echarts.EChartOption)

      echartsDomRR = echarts.init(refRR)
      echartsDomRR.setOption(rrOption.value as echarts.EChartsResponsiveOption)
    })

    const next = async () => {
      await healthRealtimeReport()
      echartsDom.setOption({
        animation: true,
        grid: {
          left: "0%",
          right: "5%",
          top: "5%",
          bottom: "5%",
          containLabel: true
        },
        tooltip: {
          show: false,
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: featureXAxisData,
          show: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "white"
            }
          }
        },
        series: [
          {
            name: "波形图",
            data: healthReportData.value
              ? healthReportData.value?.PulseWave
              : []
            // data: []
          }
        ]
      } as echarts.EChartOption)

      echartsDomRR.setOption({
        series: [
          {
            name: "RR间期",
            data: healthReportData.value
              ? healthReportData.value?.RRInterval
              : []
          }
        ]
      } as echarts.EChartsResponsiveOption)
    }
    const ready = async () => {
      await next()
    }
    ready()

    return {
      setRef,
      setRefRR,
      healthReportData,
      fatigue,
      fatigueColor,
      reportTitle,
      time
    }
  }
}
