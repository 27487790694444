
import { Ref, watch, computed, nextTick } from "vue"
import echarts from "echarts"
import { dateToTimeList } from "@/assets/script/utils/date.util"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { useI18n } from "vue-i18n"
import { WS_HOST } from "@/assets/script/constant/config.constant"
import { SignInVo, UserService } from "@/assets/script/service/user.service"
import { sleep } from "@/assets/script/utils/heighten.util"

import {
  HealthRealtimeReportData,
  HealthReportFlag
} from "../../realtime/constants"
import { ref, onDeactivated } from "vue"
import { useRouter } from "vue-router"
export default {
  setup() {
    const router = useRouter()

    const uid = router.currentRoute.value.query.uid || "" // 获取 phone 参数值，如果没有则默认为空字符串
    const deviceId = router.currentRoute.value.query.healthId || "" // 获取 password 参数值，如果没有则默认为空字符串

    const healthReportData = ref<HealthRealtimeReportData | undefined>(
      undefined
    )
    const fatigue = ref("")
    const fatigueColor = ref("")

    let timer
    let webSocket: WebSocket
    function connectWebSocket() {
      webSocket = new WebSocket(WS_HOST)

      webSocket.onmessage = (result) => {
        // 将JSON字符串解析为JavaScript对象
        const data: HealthReportFlag = JSON.parse(result.data)
        if (data.result) {
          // 获取健康实时数据
          healthReportData.value = JSON.parse(result.data).result[0]

          if (healthReportData.value && healthReportData.value.fatigue) {
            const fatigueIndex = healthReportData.value.fatigue
            switch (fatigueIndex) {
              case 1:
                fatigue.value = "良好"
                fatigueColor.value = "#2fa988"
                break
              case 2:
                fatigue.value = "轻度疲劳"
                fatigueColor.value = "#b1a62b"
                break
              case 3:
                fatigue.value = "重度疲劳"
                fatigueColor.value = "#993030"
                break
              default:
                fatigue.value = "无效的疲劳指数"
                break
            }
          }

          // console.log(healthReportData.value?.htratesuggest)
          // timer = setTimeout(() => {
          //   healthReportData.value = undefined
          //   fatigue.value = ""
          // }, 15000) // 设置一个定时器，10秒后将 healthReportData 重置为 null
        }
      }

      webSocket.onopen = () => {
        setInterval(() => {
          webSocket.send(JSON.stringify({ method: "pong" }))
        }, 3000)
      }
      webSocket.onclose = function(e) {
        console.log(
          "WebSocket 断开: " + e.code + " " + e.reason + " " + e.wasClean
        )
        console.log("尝试重新连接...")
        setTimeout(connectWebSocket, 2000) // 重新连接的间隔时间
      }

      webSocket.onerror = function(err) {
        console.error("WebSocket 错误:", err)
        setTimeout(connectWebSocket, 2000) // 重新连接的间隔时间
        // 在这里添加错误处理
      }
    }

    connectWebSocket()

    const sendRealtime = (s = false) => {
      webSocket.send(
        JSON.stringify({
          method: "healthReport",
          uid: uid,
          deviceId: "healthReport:" + deviceId,
          switch: s
        })
      )
    }

    // x轴的数据
    const t = [
      "0",
      "6",
      "12",
      "18",
      "24",
      "30",
      "36",
      "42",
      "48",
      "54",
      "60",
      "66",
      "72",
      "78",
      "84",
      "90",
      "96",
      "102",
      "108",
      "114",
      "120",
      "126",
      "132",
      "138",
      "144",
      "150",
      "156",
      "162",
      "168",
      "174",
      "180",
      "186",
      "192",
      "198",
      "204",
      "210",
      "216",
      "222",
      "228",
      "234",
      "240",
      "246",
      "252",
      "258",
      "264",
      "270",
      "276",
      "282",
      "288",
      "294",
      "300",
      "306",
      "312",
      "318",
      "324",
      "330",
      "336",
      "342",
      "348",
      "354",
      "360",
      "366",
      "372",
      "378",
      "384",
      "390",
      "396",
      "402"
    ]
    const featureOption = computed(() => {
      return {
        animation: true,
        grid: {
          left: "0%",
          right: "5%",
          top: "5%",
          bottom: "0%",
          containLabel: true
        },
        tooltip: {
          show: false,
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: t,
          show: true,
          axisLabel: {
            show: true,
            textStyle: {
              color: "white"
            }
          }
        },
        yAxis: {
          x: "center",
          type: "value",
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "gray"
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: "white"
            }
          }
        },
        series: [
          {
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 1
            },
            showSymbol: false,
            areaStyle: {
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)"
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: healthReportData.value?.PulseWave
          }
        ]
      }
    })

    const e = [
      780.0,
      800.0,
      840.0,
      830.0,
      770.0,
      790.0,
      800.0,
      820.0,
      800.0,
      770.0,
      760.0,
      760.0,
      770.0,
      790.0,
      810.0,
      810.0,
      800.0,
      750.0,
      790.0,
      850.0,
      800.0,
      800.0,
      790.0,
      750.0,
      720.0,
      740.0,
      790.0,
      820.0,
      810.0,
      780.0,
      830.0,
      850.0,
      850.0,
      800.0,
      790.0,
      800.0,
      810.0,
      770.0,
      750.0,
      770.0,
      780.0,
      810.0,
      810.0,
      780.0,
      770.0,
      810.0,
      820.0,
      790.0,
      750.0,
      750.0,
      740.0,
      740.0,
      740.0,
      750.0,
      760.0,
      720.0
    ]

    const rrOption = computed(() => {
      return {
        animation: true,
        xAxis: {
          max: 1800,
          axisLabel: {
            textStyle: {
              color: "white"
            }
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: "white"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "white"
            }
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          max: 1800,
          axisLabel: {
            textStyle: {
              color: "white"
            }
          },
          axisTick: {
            show: false,
            lineStyle: {
              color: "white"
            }
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: "white"
            }
          },
          splitLine: {
            show: false
          }
        },
        legend: {
          show: false,
          data: ["RR间期"]
        },
        visualMap: {
          show: false
        },
        tooltip: {
          show: false,
          trigger: "item"
        },
        grid: {
          left: "0%",
          right: "5%",
          top: "5%",
          bottom: "0%",
          containLabel: true
        },
        series: [
          {
            type: "scatter",
            name: "RR间期",
            symbolSize: 3,
            // data: healthReportData.value?.RRInterval?healthReportData.value?.RRInterval:e,
            data: healthReportData.value ? e : [],
            markLine: {
              animation: false,
              tooltip: {
                show: false
              },
              lineStyle: {
                type: "solid",
                color: "gray"
              },
              data: [
                [
                  { coord: [0, 0], symbol: "none" },
                  { coord: [1800, 1800], symbol: "none" }
                ]
              ]
            }
          }
        ],
        animationDelay: function(idx: number) {
          return 50 * idx
        },
        animationEasing: "elasticOut"
      }
    })

    let ref2: HTMLDivElement
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (ref2 = el)

    let refRR: HTMLDivElement
    let echartsDomRR: echarts.ECharts
    const setRefRR = (el: HTMLDivElement) => (refRR = el)

    nextTick(() => {
      echartsDom = echarts.init(ref2)
      echartsDom.setOption(featureOption.value as echarts.EChartOption)

      echartsDomRR = echarts.init(refRR)
      echartsDomRR.setOption(rrOption.value as echarts.EChartsResponsiveOption)
    })

    setInterval(function() {
      echartsDom.setOption({
        series: [
          {
            name: "波形图",
            data: healthReportData.value?.PulseWave
          }
        ]
      } as echarts.EChartOption)

      echartsDomRR.setOption({
        series: [
          {
            name: "RR间期",
            data: healthReportData.value ? e : []
          }
        ]
      } as echarts.EChartsResponsiveOption)
    }, 1000)

    const next = async () => {
      await sleep(1000)
      sendRealtime(true)
    }
    const ready = async () => {
      await next()
    }
    ready()

    return {
      setRef,
      setRefRR,
      healthReportData,
      fatigue,
      fatigueColor
    }
  }
}
