
import {
  format as scoreFormat,
  convertToPercentage as percent,
  z
} from "@/assets/script/utils/health.util"

import { watch, computed, nextTick } from "vue"
import echarts from "echarts"
import { dateToTimeList } from "@/assets/script/utils/date.util"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { useI18n } from "vue-i18n"
import { WS_HOST } from "@/assets/script/constant/config.constant"
import { SignInVo, UserService } from "@/assets/script/service/user.service"
import { sleep } from "@/assets/script/utils/heighten.util"

import { HealthRealtimeData } from "../../realtime/constants"
import { ref, onDeactivated } from "vue"
import { useRouter } from "vue-router"
interface Props {
  yAxis: number[]
  startTime: number
}

export default {
  // props: ["yAxis", "startTime", "averageHeartBeatRate"],
  setup(props: Props) {
    const router = useRouter()

    const uid = router.currentRoute.value.query.uid || "" // 获取 phone 参数值，如果没有则默认为空字符串
    const deviceId = router.currentRoute.value.query.healthId || "" // 获取 password 参数值，如果没有则默认为空字符串

    // const NEW_WS_HOST ="ws://192.168.27.133:9632/ws"
    // const webSocket = new WebSocket(NEW_WS_HOST)

    const healthRealtimeData = ref<HealthRealtimeData | undefined>(undefined)
    const acData: number[] = [0] // 在外部作用域中声明并初始化 acData 变量
    const count = ref(0)

    let timer: number
    const webSocket = new WebSocket(WS_HOST)
    webSocket.onmessage = (result) => {
      clearTimeout(timer) // 在收到数据时重置定时器
      const data: HealthRealtimeData = JSON.parse(result.data)
      // console.log(data)
      if (data.dataKey === "realtime") {
        console.log("HealthRealtimeData")
        healthRealtimeData.value = data

        // acData = acData.concat(healthRealtimeData.value.acData) // 将新数据与已有数据合并
        acData.push(...healthRealtimeData.value.acData)
        count.value += 1
        // console.log(acData)
        // const now = new Date()
        // console.log(now)
      }
      // 设置三秒内没有收到数据时将 healthRealtimeData 的值设为undefined
      timer = setTimeout(() => {
        healthRealtimeData.value = undefined
        // acData = []
      }, 2000) // 1500->2000
    }

    webSocket.onopen = () => {
      setInterval(() => {
        webSocket.send(JSON.stringify({ method: "pong" }))
      }, 3000)
    }
    webSocket.onclose = function(e) {
      console.log(
        "websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean
      )
      const now = new Date()
      console.log(now)
      // Reconnect
      setTimeout(function() {
        const webSocket = new WebSocket(WS_HOST) // 重新创建 WebSocket 实例
        webSocket.onopen = () => {
          setInterval(() => {
            webSocket.send(JSON.stringify({ method: "pong" }))
          }, 3000)
        }
        webSocket.onmessage = (result) => {
          clearTimeout(timer) // 在收到数据时重置定时器
          const data: HealthRealtimeData = JSON.parse(result.data)
          if (data.dataKey === "realtime") {
            console.log("HealthRealtimeData")
            healthRealtimeData.value = data
            acData.push(...healthRealtimeData.value.acData)
            count.value += 1
          }
          // 设置三秒内没有收到数据时将 healthRealtimeData 的值设为undefined
          timer = setTimeout(() => {
            healthRealtimeData.value = undefined
          }, 2000) // 1500->2000
        }
      }, 3000)
    }

    const sendRealtime = (s = false) => {
      webSocket.send(
        JSON.stringify({
          method: "health",
          uid: uid,
          deviceId: deviceId,
          switch: s
        })
      )
    }

    const { t } = useI18n()

    const option = computed(() => {
      return {
        animation: true,
        grid: {
          left: 0,
          top: 10,
          bottom: 20, // 调整底部间距，使得 x 轴标签不占据高度空间
          right: 0,
          containLabel: false
        },

        tooltip: {
          show: false,
          trigger: "axis"
        },

        dataZoom: [
          {
            type: "inside",
            start: 0, //数据窗口范围的起始百分比,表示1%
            end: 15 //数据窗口范围的结束百分比,表示15%坐标
            // filterMode: 'filter'
          },
          {
            type: "slider", //slider表示有滑动块的，
            show: true,
            left: 0,
            right: 10,
            bottom: 15,
            brushSelect: true,
            moveHandleSize: 10,
            start: 0,
            end: 15
          }
        ],
        xAxis: {
          type: "category",
          boundaryGap: [0, "100%"],
          show: false,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: true, // 轴线
            lineStyle: {
              color: "#999", // 刻度线颜色
              type: "dashed" // 刻度线类型为虚线
            }
          },
          axisTick: {
            show: false // 隐藏坐标轴刻度
          },
          axisPointer: {
            show: false // 隐藏坐标轴指示器
          }
        },
        yAxis: {
          type: "value",
          boundaryGap: false,
          max: 300,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "gray"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              origin: "start",
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)"
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: acData
          }
        ]
      }
    })

    let ref2: HTMLDivElement
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (ref2 = el)
    nextTick(() => {
      echartsDom = echarts.init(ref2)
      echartsDom.setOption(option.value as echarts.EChartOption)
    })

    setInterval(function() {
      // 生成一个与 acData 长度相同的数组作为 x 轴数据
      const xAxisData: number[] = Array.from(
        { length: acData.length },
        (_, index) => index + 1
      )

      echartsDom.setOption({
        xAxis: {
          data: xAxisData
        },
        series: [
          {
            name: "波形图",
            data: acData
          }
        ]
      } as echarts.EChartOption)
    }, 200)

    const next = async () => {
      await sleep(1000)
      sendRealtime(true)
    }
    const ready = async () => {
      await next()
    }
    ready()

    return {
      setRef,
      scoreFormat,
      percent,
      z,
      healthRealtimeData,
      count
    }
  }
}
