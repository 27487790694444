/**
 * 沉睡
 */
export function sleep(wait: number) {
  return new Promise((resolve) => {
    setTimeout(resolve, wait)
  })
}

/**
 * 防抖函数
 */
export function debounce(fn: Function, delay: number, immediate: boolean) {
  let timer: number | undefined
  return (...args: any) => {
    if (immediate) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      fn.apply(this, args) // 确保引用函数的指向正确，并且函数的参数也不变
      immediate = false
      return
    }
    clearTimeout(timer)
    timer = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
      // @ts-ignore
      fn.apply(this, args)
    }, delay)
  }
}

/**
 * 节流函数
 */
export function throttle(fn: Function, wait: number) {
  let timeout: number | undefined
  return function(...args: any) {
    if (!timeout) {
      timeout = setTimeout(() => {
        timeout = undefined
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        fn.apply(this, args)
      }, wait)
    }
  }
}

export function generateUUID() {
  let d = new Date().getTime()
  if (window.performance && typeof window.performance.now === "function") {
    d += performance.now()
  }
  return "xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0
    d = Math.floor(d / 16)
    return (c == "x" ? r : (r & 0x3) | 0x8).toString(16)
  })
}
