export class Result<T> {
  status: number
  message?: string
  data?: T

  constructor(status: number, message?: string, data?: T) {
    this.status = status
    this.message = message
    this.data = data
  }
}

export class ExpoConfigViewModel {
  phone: string
  password: string
  bedId: string
  mainBoardId: string
  addressCode: number

  constructor() {
    this.phone = "18512616096"
    this.password = "q123456"
    this.bedId = "rv4b2849oelrw"
    this.mainBoardId = "ASM9260T11652061862800113"
    this.addressCode = 1
  }
}
