export default [
  {
    path: "/report/:id",
    props: true,
    component: () => import("../Push.vue")
  },
  {
    path: "/report/:type/:equipmentId",
    props: true,
    component: () => import("../Main.vue"),
    children: [
      {
        path: "/report/day/:equipmentId/:date?/:position?",
        props: true,
        component: () => import("../Day.vue")
      },
      {
        path: "/report/month/:equipmentId/:date?/:position?",
        props: true,
        component: () => import("../Month.vue")
      }
    ]
  }
]
