
import {
  format as scoreFormat,
  convertToPercentage as percent,
  z
} from "@/assets/script/utils/health.util"

import { WS_HOST } from "@/assets/script/constant/config.constant"
import { sleep } from "@/assets/script/utils/heighten.util"

import { ref, onDeactivated } from "vue"
import { useRouter } from "vue-router"
interface Props {
  yAxis: number[]
  startTime: number
}

import {
  BedStatus,
  Position,
  RealtimeData,
  RealtimeDataType
} from "../../realtime/constants"

export default {
  setup(props: Props) {
    const router = useRouter()

    const uid = router.currentRoute.value.query.uid || "" // 获取 phone 参数值，如果没有则默认为空字符串
    const sleepDeviceId = router.currentRoute.value.query.sleepDeviceId || "" // 获取 password 参数值，如果没有则默认为空字符串
    // 0 左侧 1 右侧
    const position = router.currentRoute.value.query.position || "" // 获取 password 参数值，如果没有则默认为空字符串

    // const sleepDeviceId =  "19hej7z019vyf" // 获取 password 参数值，如果没有则默认为空字符串
    // // 0 左侧 1 右侧
    // const position ="0" // 获取 password 参数值，如果没有则默认为空字符串

    // const uid = "f98ef53394f8469496fe3bef2b7dfc30" // 获取 phone 参数值，如果没有则默认为空字符串
    // const deviceId = "rv4b2849oelrw" // 获取 password 参数值，如果没有则默认为空字符串

    // const deviceId=sleepDeviceId

    const left = ref(undefined as RealtimeData | undefined)
    const right = ref(undefined as RealtimeData | undefined)

    const positionData = ref(undefined as RealtimeData | undefined)

    const webSocket = new WebSocket(WS_HOST)
    webSocket.onmessage = (result) => {
      const data: RealtimeData = JSON.parse(result.data)
      // console.log(data)
      if (data.type === RealtimeDataType.LEFT) {
        left.value = data
      }
      if (data.type === RealtimeDataType.RIGHT) {
        right.value = data
      }
      if (position === "0") {
        positionData.value = left.value
      }
      if (position === "1") {
        positionData.value = right.value
      }
    }

    webSocket.onopen = () => {
      setInterval(() => {
        webSocket.send(JSON.stringify({ method: "pong" }))
      }, 3000)
    }
    webSocket.onclose = function(e) {
      console.log(
        "websocket 断开: " + e.code + " " + e.reason + " " + e.wasClean
      )
      // Reconnect
      setTimeout(function() {
        webSocket.onmessage = (result) => {
          const data: RealtimeData = JSON.parse(result.data)
          // console.log(data)
          if (data.type === RealtimeDataType.LEFT) {
            left.value = data
          }
          if (data.type === RealtimeDataType.RIGHT) {
            right.value = data
          }
          if (position === "0") {
            positionData.value = left.value
          }
          if (position === "1") {
            positionData.value = right.value
          }
        }

        webSocket.onopen = () => {
          setInterval(() => {
            webSocket.send(JSON.stringify({ method: "pong" }))
          }, 3000)
        }
      }, 3000)
    }

    const sendRealtime2 = (s = false) => {
      webSocket.send(
        JSON.stringify({
          method: "newSleepRealtime",
          uid: uid,
          deviceId: sleepDeviceId,
          switch: s
        })
      )
    }

    const next = async () => {
      await sleep(2000)
      sendRealtime2(true)
    }
    const ready = async () => {
      await next()
    }
    ready()

    return {
      left,
      right,
      positionData
    }
  }
}
