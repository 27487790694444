export default [
  {
    path: "/realtime",
    props: true,
    meta: {
      keepAlive: true
    },
    component: () => import("../Main.vue")
  }
]
