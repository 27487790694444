import { useI18n } from "vue-i18n"
import { i18n } from "../../../../locales"
import echarts from "echarts"
import { ref, onDeactivated } from "vue"
const max = 23 // 前端包23个包
export function format(cntPacket: number): { desc: string; style: string } {
  // console.log("cntPacket:" + cntPacket)
  if (cntPacket == 31) {
    return {
      // 数据采集异常
      desc: i18n.global.tm("healthUtil.abnormalDataCollection").toString(),
      style: "#FF4254"
    }
  } else if (cntPacket == 30) {
    return {
      // 设备断开连接
      desc: i18n.global.tm("healthUtil.deviceDisconnected").toString(),
      style: "#FF4254"
    }
  } else if (cntPacket < max && cntPacket > 0) {
    return {
      // 检测进行中
      desc: i18n.global.tm("healthUtil.detectionInProgress").toString(),
      style: "#6BCCF9"
    }
  } else if (cntPacket == max) {
    return {
      // 检测完成
      desc: i18n.global.tm("healthUtil.detectionCompleted").toString(),
      style: "#6BCCF9"
    }
  } else if (cntPacket == 0) {
    return {
      // 请轻触开始
      desc: i18n.global.tm("healthUtil.lightlyTouch").toString(),
      style: "#6BCCF9"
    }
  } else {
    return {
      desc: "",
      style: ""
    }
  }
}

export function convertToPercentage(value: number): number {
  if (value <= 0) {
    return 0
  }
  if (value >= max) {
    return 100
  }
  value = value == 1 ? 2 : value //加快进度条
  const parent = (value / max) * 100
  return parent
}

export function z(t: string[], a: number[]): { option: any } {
  const option = ref({
    animation: true,
    grid: {
      left: "0%",
      right: "1%",
      top: "2%",
      bottom: "0%",
      containLabel: false
    },
    tooltip: {
      show: false,
      trigger: "axis"
    },
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 15
      },
      {
        left: 1,
        right: 10,
        bottom: 10,
        brushSelect: true,
        moveHandleSize: 10,
        start: 0,
        end: 15
      }
    ],
    xAxis: {
      type: "category",
      boundaryGap: [0, "100%"],
      data: t,
      show: false,
      max: t.length,
      axisLabel: {
        show: false
      }
    },
    yAxis: {
      type: "value",
      boundaryGap: false,
      max: 300,
      splitLine: {
        lineStyle: {
          type: "dashed",
          color: "gray"
        }
      },
      axisLabel: {
        show: false
      }
    },
    series: [
      {
        type: "line",
        stack: "Total",
        smooth: true,
        lineStyle: {
          width: 0
        },
        showSymbol: false,
        areaStyle: {
          opacity: 0.8,
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
            { offset: 0, color: "rgb(128, 255, 165)" },
            { offset: 1, color: "rgb(1, 191, 236)" }
          ])
        },
        emphasis: {
          focus: "series"
        },
        data: a
      }
    ]
  })

  return { option }
}
