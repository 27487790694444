import { format } from "../utils/date.util"

export default {
  format(date: Date, formatString?: string) {
    return format(date, formatString)
  },
  toFixed(number: number | string, fractionDigits = 2, showNaN: boolean) {
    if (typeof number === "number") {
      return number.toFixed(fractionDigits)
    } else if (showNaN) {
      return "NaN"
    } else {
      number = 0
    }
  },
  toMore(number: number, threshold = 100) {
    if (number > threshold) {
      return threshold - 1
    }
    return number
  }
  // ago(date: Date) {
  //   return ago(date)
  // }
}
