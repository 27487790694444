export enum Position {
  LEFT,
  RIGHT
}

export enum RealtimeDataType {
  LEFT = "leftRealtime",
  RIGHT = "rightRealtime"
}

export enum BedStatus {
  ON,
  OUT
}

export interface RealtimeData {
  breath: number
  heart: number
  temp: number
  hum: number
  bedStatus: BedStatus
  type: RealtimeDataType
  uid: string
}

export interface HealthRealtimeData {
  acData: number[]
  bk: number
  dataKey: string
  heartRate: number
  nn50: number
  pnn50: number
  rmssd: number
  rra: number[]
  rsv: number[]
  sdnn: number
  spo2: number
  state: number
  healthState: number
  percentage: number
}

export interface HealthReportFlag {
  result: number[]
}
export interface HealthRealtimeReportData {
  start_time: string
  name: string
  htrate: number
  htratesymptom: string
  htratereasons: string
  htratesuggest: string
  htratesyresu: string
  spo: number
  sposymptom: string
  sporeasons: string
  sposuggest: string
  sposyresu: string
  bk: number
  bksymptom: string
  bkreasons: string
  bksuggest: string
  bksyresu: string
  fatigue: number
  fatiguesymptom: string
  fatiguereasons: string
  fatiguesuggest: string
  fatiguesyresu: string
  rr: number
  rrsymptom: string
  rrreasons: string
  rrsuggest: string
  rrsyresu: string
  hrv: number
  hrvsymptom: string
  hrvreasons: string
  hrvsuggest: string
  hrvsyresu: string
  fatigue4lv: number
  fatigue4lvsymptom: string
  fatigue4lvreasons: string
  fatigue4lvsuggest: string
  fatigue4lvsyresu: string
  arrhythmia: number
  arrhythmiasymptom: string
  arrhythmiareasons: string
  arrhythmiasuggest: string
  arrhythmiasyresu: string
  sdbp: {
    sbp: number
    dbp: number
  }
  sdbpsymptom: string
  sdbpreasons: string
  sdbpsuggest: string
  sdbpsyresu: string
  RRInterval: number[] // 这里假设是一个数组
  PulseWave: number[] // 这里假设是一个数组
  sdbptrends: number
  CardiovascularAge: number
  report: string
  session_id: string
  errmessage: string
  errcode: number
}

// 假设 HealthService.healthReportHistory 函数的返回类型
interface HealthReportHistoryResponse {
  data: any[] // 假设 data 是一个数组，包含了健康报告的历史数据
  status: string // 假设 status 是一个字符串，表示请求的状态
  message?: string // 假设 message 是一个可选的字符串，表示请求的消息
}

export interface HealthReportHistoryData {
  id: string
  createDate: string
}
