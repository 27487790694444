import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import config from "../views/config/script/routes"
import count from "../views/count/script/routes"
import bigScreen from "../views/bigScreen/script/routes"
import following from "../views/following/script/routes"
import gravity from "../views/gravity/script/routes"
import Guide from "../views/Guide.vue"
import Home from "../views/Home.vue"
import instructionalVideo from "../views/instructional-video/script/routes"
import qrcode from "../views/qrcode/script/routes"
import realtime from "../views/realtime/script/routes"
import report from "../views/report/script/routes"
import health from "../views/health/health.vue"
import bigScreenHealth from "../views/bigScreen/components/bigScreenhealth.vue"
import bigHealthRealTimeReport from "../views/bigScreen/components/bigHealthRealTimeReport.vue"
import bigSleepRealtime from "../views/bigScreen/components/bigSleepRealtime.vue"
import healthReport from "../views/health/healthReport.vue"
import healhReportHistory from "../views/health/healhReportHistory.vue"

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: []
  },
  {
    path: "/health",
    name: "Health",
    component: health,
    children: []
  },
  {
    path: "/bigScreenHealth",
    name: "bigScreenHealth",
    component: bigScreenHealth,
    children: []
  },
  {
    path: "/bigHealthRealTimeReport",
    name: "bigHealthRealTimeReport",
    component: bigHealthRealTimeReport,
    children: []
  },
  {
    path: "/bigSleepRealtime",
    name: "bigSleepRealtime",
    component: bigSleepRealtime,
    children: []
  },
  {
    path: "/healthReport/:healthReportId/:healthReportFalg",
    name: "healthReport",
    component: healthReport,
    props: true,
    meta: { keepAlive: true },
    children: []
  },
  {
    path: "/healhReportHistory/:deviceCiphertextId/:userId",
    name: "healhReportHistory",
    component: healhReportHistory,
    props: true,
    meta: { keepAlive: true },
    children: []
  },
  {
    path: "/guide",
    name: "Guide",
    component: Guide,
    children: [
      {
        path: "/guide/MpeV3B-1",
        component: () => import("@/views/guide/MpeV3B-1.vue")
      },
      {
        path: "/guide/MpeV3B-2",
        component: () => import("@/views/guide/MpeV3B-2.vue")
      },
      {
        path: "/guide/MpeV3B-4",
        component: () => import("@/views/guide/MpeV3B-4.vue")
      },
      {
        path: "/guide/MpeV3B-5",
        component: () => import("@/views/guide/MpeV3B-5.vue")
      },
      {
        path: "/guide/hj001",
        component: () => import("@/views/guide/Hj001.vue")
      },
      {
        path: "/guide/hj006",
        component: () => import("@/views/guide/Hj006.vue")
      }
    ]
  },
  {
    path: "/agreement",
    name: "Agreement",
    component: Guide,
    children: [
      {
        path: "/agreement/privacy",
        component: () => import("@/views/agreement/Privacy.vue"),
        meta: {
          title: "隐私政策"
        }
      },
      {
        path: "/agreement/privacy-c007",
        component: () => import("@/views/agreement/Privacy-C007.vue"),
        meta: {
          title: "隐私政策"
        }
      },
      {
        path: "/agreement/privacy-c007-en",
        component: () => import("@/views/agreement/Privacy-C007-En.vue"),
        meta: {
          title: "Privacy policy"
        }
      },
      {
        path: "/agreement/user-c007",
        component: () => import("@/views/agreement/User-C007.vue"),
        meta: {
          title: "用户协议"
        }
      },
      {
        path: "/agreement/user-c007-en",
        component: () => import("@/views/agreement/User-C007-En.vue"),
        meta: {
          title: "User agreement"
        }
      },
      {
        path: "/agreement/user",
        component: () => import("@/views/agreement/User.vue"),
        meta: {
          title: "用户协议"
        }
      }
    ]
  },
  ...realtime,
  ...report,
  ...qrcode,
  ...count,
  ...bigScreen,
  ...following,
  ...gravity,
  ...config,
  ...instructionalVideo
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: routes as RouteRecordRaw[]
})

// 根据路由设置标题
router.beforeEach((to, from, next) => {
  /*路由发生改变修改页面的title */
  if (to.meta.title) {
    document.title = to.meta.title || "MPE"
  }
  next()
})

export default router
