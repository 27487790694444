import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  class: "page",
  style: {"padding":"16px 12px 0px 12px"}
}
const _hoisted_2 = {
  class: "title",
  style: {"margin-top":"25px"}
}
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { class: "title" }
const _hoisted_5 = { class: "report-container" }
const _hoisted_6 = { class: "report-item" }
const _hoisted_7 = { class: "report-time-font" }
const _hoisted_8 = { class: "report-time-right" }
const _hoisted_9 = { class: "report-time-font" }
const _hoisted_10 = { class: "date-picker-container" }

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_van_icon = _resolveComponent("van-icon")
  const _component_van_button = _resolveComponent("van-button")
  const _component_my_card_body = _resolveComponent("my-card-body")
  const _component_van_datetime_picker = _resolveComponent("van-datetime-picker")
  const _component_my_card = _resolveComponent("my-card")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode(_component_my_card, { style: {"background-color":"#1f3b6a"} }, {
      default: _withCtx(() => [
        _createVNode(_component_my_card_body, null, {
          default: _withCtx(() => [
            _createVNode("div", _hoisted_2, [
              _createVNode(_component_van_button, {
                class: "custom-button",
                onClick: _cache[1] || (_cache[1] = $event => ($setup.showPicker = true))
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString($setup.currentButtonText) + " ", 1 /* TEXT */),
                  _createVNode(_component_van_icon, {
                    name: "arrow-down",
                    class: "arrow-icon"
                  })
                ]),
                _: 1
              })
            ]),
            (
            !$setup.healthReportHistoryListData ||
              $setup.healthReportHistoryListData.length === 0
          )
              ? (_openBlock(), _createBlock("div", _hoisted_3, [
                  _createVNode("div", _hoisted_4, _toDisplayString(_ctx.$t("healhReportHistory.noData")), 1)
                ]))
              : _createCommentVNode("", true),
            _createVNode("div", _hoisted_5, [
              (_openBlock(true), _createBlock(_Fragment, null, _renderList($setup.healthReportHistoryListData, (item, index) => {
                return (_openBlock(), _createBlock("div", {
                  key: index,
                  class: "report-time-container"
                }, [
                  _createVNode("div", _hoisted_6, [
                    _createVNode("div", _hoisted_7, _toDisplayString(_ctx.$t("healhReportHistory.reportTime")) + " " + _toDisplayString(item.createDate.slice(11, 19)), 1)
                  ]),
                  _createVNode("div", _hoisted_8, [
                    _createVNode("div", _hoisted_9, [
                      _createVNode("div", {
                        class: "read-font inline-block-element",
                        onClick: $event => (
                    $setup.redirectToReport(item.id, item.createDate.slice(0, 19))
                  )
                      }, _toDisplayString(_ctx.$t("healhReportHistory.clickToView")), 9, ["onClick"])
                    ])
                  ])
                ]))
              }), 128 /* KEYED_FRAGMENT */))
            ])
          ]),
          _: 1
        }),
        _createVNode("div", _hoisted_10, [
          ($setup.showPicker)
            ? (_openBlock(), _createBlock(_component_van_datetime_picker, {
                key: 0,
                modelValue: $setup.currentDate,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => ($setup.currentDate = $event)),
                type: "date",
                onConfirm: $setup.handleConfirm,
                onCancel: _cache[3] || (_cache[3] = $event => ($setup.showPicker = false)),
                "confirm-button-text": _ctx.$t('picker.confirm'),
                "cancel-button-text": _ctx.$t('picker.cancel')
              }, null, 8, ["modelValue", "onConfirm", "confirm-button-text", "cancel-button-text"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ]))
}